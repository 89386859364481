$.fn.pbToggleModal = function() {
    return this.each(function() {
        var $contents = $(this);

        if ($contents.parents('.modal-container').length > 0) {
            $contents.pbCloseModal();
        }
        else {
            $contents.pbOpenModal();
        }
    });
};

$.fn.pbOpenModal = function() {
    return this.each(function() {
        var $contents = $(this);

        var $screen = $('<div class="modal-background"></div>');
        var $modal = $('<div class="modal-container"></div>');
        $screen.append($modal);

        // try and keep the contents in the original dom position (so forms are
        // kept and tact, and they can easily be put back later), but if it isn't
        // in the dom, then just append to the body
        if ($contents.parents('body').length) {
            $contents.after($screen);
        }
        else {
            $(document.body).append($screen);
        }

        $modal.append($contents);

        $contents.trigger('pbModalOpened');

        $screen.on('click', function(e) {
            if (e.target == $screen[0]) {
                $contents.pbToggleModal();
            }
        });
    });
};

$.fn.pbCloseModal = function() {
    return this.each(function() {
        var $contents = $(this);

        var $screen = $contents.parents('.modal-background');
        $contents.detach();

        $screen.off();
        $screen.remove();

        $contents.trigger('pbModalClosed');
    });
};
