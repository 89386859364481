(function() {
    var DEFAULT_OPTIONS = {
        changeURL:               true,
        replaceHistory:          true,
        loadingContentClass:     'pb-loading-content',
        doneLoadingContentClass: 'pb-done-loading-content',
    };

    $.fn.pbFormDynamicLoad = function(loadInto, options) {
        this.each(function() {
            load($(this), $(loadInto), options);
        });
        return this;
    };

    $.pbOnNewContent(function($container) {
        //$container.find('[pb-form-dynamic-load]').each(function(i,el) { console.log(el); });
        $container.find('[pb-form-dynamic-load]').on('submit', dynamicForm_submit_listener);
    });

    function dynamicForm_submit_listener(e) {
        var $this = $(this);
        var loadIntoSelector = $this.attr('pb-form-dynamic-load');
        var $loadInto = $(loadIntoSelector);

        $(this).pbFormDynamicLoad($loadInto);

        e.preventDefault();
        return false;
    }

    function load($form, $loadInto, options) {

        options = $form.pbGetOptions(options, 'dynamic-form', DEFAULT_OPTIONS);

        if ($loadInto.length < 1) {
            console.error('cannot find target for dynaminc form, skipping');
            return;
        }

        var method = ($form.attr('method') || 'GET');

        if (method.toUpperCase() !== 'GET') {
            console.error('pbjquery dynamic form only works with GET requests right now');
            return;
        }

        var data       = parseQueryString($form.serialize());
        var url        = ($form.attr('action') || window.location+'');
        var query      = '';
        var queryIndex = url.indexOf('?');

        if (queryIndex > -1) {
            query = url.slice(queryIndex+1);
            url = url.slice(0, queryIndex);

            data = $.extend(parseQueryString(query), data);
        }

        url += '?' + $.param(data);

        var loadOptions = {
            changeURL:      options.changeURL,
            replaceHistory: options.replaceHistory,
        };

        //console.log(url, loadOptions);

        $loadInto.addClass(options.loadingContentClass).removeClass(options.doneLoadingContentClass);
        $loadInto.pbLoadFrom(url, loadOptions, function() {
            $loadInto.removeClass(options.loadingContentClass).addClass(options.doneLoadingContentClass);
        });

        return false;
    }

    // https://gist.github.com/kares/956897
    function parseQueryString(query) {
        var re = /([^&=]+)=?([^&]*)/g;
        var decodeRE = /\+/g;  // Regex for replacing addition symbol with a space
        var decode = function (str) {return decodeURIComponent( str.replace(decodeRE, " ") );};

        var params = {}, e;
        while ( e = re.exec(query) ) {
            var k = decode( e[1] ), v = decode( e[2] );
            if (k.substring(k.length - 2) === '[]') {
                k = k.substring(0, k.length - 2);
                (params[k] || (params[k] = [])).push(v);
            }
            else params[k] = v;
        }
        return params;
    }


})();
