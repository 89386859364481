(function() {
    var CAN_CHANGE_HISTORY = window.history && ('replaceState' in window.history) && ('pushState' in window.history);

    var popStateListenerAdded = false;

    // this is definitely a potential memory leak
    var targets = [];

    $.pbCheckIfLoadFromWorks = function() {
        return CAN_CHANGE_HISTORY;
    };

    $.fn.pbLoadFrom = function(url, options, cb) {
        if (typeof cb == 'undefined') {
            cb = options;
            options = {};
        }

        if (typeof options == 'undefined') {
            options = {};
        }

        url = ensureNotRelative(url);

        var context = {
            $target: this,
            options: options,
            cb: cb,
            url: url,
        };

        url += (url.indexOf('?') >= 0 ? '&' : '?') + 'viaajax=1';

        if (context.options.changeURL) {
            if (CAN_CHANGE_HISTORY) {
                setHistory(context.url, context.$target, context.options.replaceHistory);
                $.ajax({
                    dataType: 'json',
                    url: url,
                    success: onSuccess,
                    error: onError,
                    context: context,
                });
            }
            else {
                window.location = context.url;
            }
        }

    };

    function ensurePopStateListenerAdded() {
        if (popStateListenerAdded) {
            return;
        }
        popStateListenerAdded = true;

        // TODO figure out how to get this to work properly.  Until I do, disable
        // it
        //$(window).bind('popstate', popStateListener);
    }

    function popStateListener(e) {
        var state = e.originalEvent.state;
        if (state) {
            var url = state.url;
            var target = targets[state.index];
        }
    }

    function setHistory(url, $target, replace) {
        ensurePopStateListenerAdded();

        if (replace) {
            window.history.replaceState({url: url}, 'title', url);
        }
        else {
            var target = $target[0];
            for (var i = 0; i < targets.length; i++) {
                if (target == targets[i]) {
                    break;
                }
            }
            var targetIndex = i;
            if (i == targets.length) {
                targets.push(target);
            }
            window.history.pushState({url: url, index: targetIndex}, 'title', url);
        }
    }

    function onSuccess(response) {
        // set content of page to returned content
        this.$target.html(response.content);

        if (response.js) {
            /*
            // TODO THINK THROUGH THIS SOME MORE
            if (response.js.files) {
                for (var i = 0; i < response.js.files.length; i++) {
                    // run js for the new content
                    var s = document.createElement('script');
                    s.src = response.js.files[i];
                    document.body.appendChild(s);
                }
            }
            */

            if (response.js.scripts) {
                for (var i = 0; i < response.js.scripts.length; i++) {
                    // run js for the new content
                    var s = document.createElement('script');
                    pb.setHTML(s, response.js.scripts[i]);
                    document.body.appendChild(s);
                }
            }
        }

        $.pbNewContent(this.$target);

        document.title = response.title;

        // google analytics page view
        window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        try {
            ga('set', 'location', this.url);
            ga('send', 'pageview');
        } catch (er) {}

        // refresh google ads
        if (typeof googletag != 'undefined') {
            //(ad blockers block loading of googleads code so method may not be there)
            if (googletag.hasOwnProperty('pubads')) {
                try {
                    googletag.pubads().refresh();
                }
                catch (er) {}
            }
        }

        if (this.cb) {
            this.cb();
        }
    }

    function onError() {
        //alert('error');
        //console.log(arguments);
        window.location.reload();
    }

    function ensureNotRelative(url) {
        if (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) {
            // do nothing
        }
        else if (url.indexOf('/') === 0) {
            // do nothing
        }
        else if (url.indexOf('?') === 0) {
            url = window.location.pathname + url;
        }
        else {
            var parts = window.location.pathname.split('/');
            var popped = parts.pop();
            if (popped === '') {
                parts.pop();
            }
            var pathname = parts.join('/') + '/';
            url = pathname + url;
        }

        return url;
    }
})();
