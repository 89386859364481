(function() {
    var DEFAULT_OPTIONS = {
        changeURL:               true,
        replaceHistory:          true,
        loadingContentClass:     'pb-loading-content',
        doneLoadingContentClass: 'pb-done-loading-content',
    };

    $.fn.pbLinkDynamicLoad = function(url, loadInto, options) {
        this.each(function() {
            load($(this), url, $(loadInto), options);
        });
        return this;
    };

    $.pbOnNewContent(function($container) {
        $container.find('[pb-link-dynamic-load]').on('click', dynamicLink_click_listener);
    });

    function dynamicLink_click_listener(e) {
        var $this = $(this);
        var loadIntoSelector = $this.attr('pb-link-dynamic-load');
        var $loadInto = $(loadIntoSelector);

        var href = $this.attr('href');

        $(this).pbLinkDynamicLoad(href, $loadInto);

        e.preventDefault();
        return false;
    }

    function load($link, url, $loadInto, options) {

        options = $link.pbGetOptions(options, 'dynamic-link', DEFAULT_OPTIONS);

        if ($loadInto.length < 1) {
            console.error('cannot find target for dynaminc link, skipping');
            return;
        }

        //var $parent = $loadInto.parent().first();
        //$loadInto.remove();
        $loadInto.addClass(options.loadingContentClass).removeClass(options.doneLoadingContentClass);
        $loadInto.pbLoadFrom(url, {changeURL: options.changeURL, replaceHistory: options.replaceHistory}, function() {
            $loadInto.removeClass(options.loadingContentClass).addClass(options.doneLoadingContentClass);
        });

        return false;
    }


})();
