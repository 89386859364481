(function() {
    $.fn.pbToggleZoom = function() {
        return this.each(function() {
            var $contents = $(this);
            if ($contents.data('$placeholder')) {
                $contents.pbCloseZoom();
            }
            else {
                $contents.pbOpenZoom();
            }
        });
    };

    $.fn.pbCloseZoom = function() {
        return this.pbCloseModal();
    };

    $.fn.pbOpenZoom = function() {
        return this.each(function() {
            var $contents = $(this);
            // opening
            var $placeholder = $('<div class="modal-placeholder"></div>');

            var width = $contents.outerWidth();
            var height = $contents.outerHeight();
            $placeholder.width(width);
            $placeholder.height(height);
            $placeholder.css('margin-bottom', $contents.css('margin-bottom'));
            $placeholder.css('margin-top', $contents.css('margin-top'));
            $placeholder.css('margin-right', $contents.css('margin-right'));
            $placeholder.css('margin-left', $contents.css('margin-left'));

            $contents.after($placeholder);
            $contents.data('$placeholder', $placeholder);
            $contents.pbOpenModal();

            $contents.on('pbModalClosed', modalClosed);

            $contents.trigger('pbZoomOpened');
        });
    };

    function modalClosed(e) {
        var $contents = $(this);
        var $placeholder = $contents.data('$placeholder');
        $placeholder.before($contents);
        $placeholder.remove();
        $contents.removeData('$placeholder');

        $contents.off('pbModalClosed', modalClosed);
        $contents.trigger('pbZoomClosed');
    }
})();
