$.fn.pbGetOptions = function(options, namespace, defaults) {
    var $el = this;

    if (!$.isPlainObject(options)) {
        options = {};
    }

    if ($el && namespace) {
        for (var key in defaults) {
            var datakey = namespace+key.substr(0,1).toUpperCase()+key.substr(1).toLowerCase();
            if (typeof options[key] === 'undefined' && $el.data(datakey)) {
                options[key] = $el.data(datakey);
            }
        }
    }

    options = $.extend({}, defaults, options);

    return options;
};
