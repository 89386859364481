$.rmsSend = function(rmsData, rmsSuccess, rmsError) {
    var data = {
        rmsP: 'j1',
        rmsD: JSON.stringify(rmsData),
    };

    var url = '/rms/index.php';

    $.ajax({
        dataType: 'json',
        type: 'post',
        url: url,
        data: data,
        success: ajaxSuccess,
        error: ajaxError,
    });

    function ajaxSuccess(data, details) {
        if (data.rmsS) {
            if (typeof rmsSuccess == 'function') { rmsSuccess(data, details); }
        }
        else if (rmsError) {
            rmsError(data.rmsM, details);
        }
        else {
            alert(data.rmsM);
        }
    }

    function ajaxError(xhr, message, details) {
        if (rmsError) {
            rmsError(details);
        }
        else {
            pb.errorHandler({message: message, error: details});
        }
    }
};
