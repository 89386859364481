(function() {
    $.fn.pbTextfieldValue = function(val) {
        if (typeof val == 'undefined') {
            return getValue.call(this);
        }
        else {
            // set value
            setValue.call(this, val);
        }
    };

    $.fn.pbTextfieldCursorPosition = function(val) {
        if (typeof val == 'undefined') {
            return getCursorPosition.call(this);
        }
        else {
            // set value
            setCursorPosition.call(this, val);
        }
    };

    $.fn.pbTextfieldCurrentLine = function(val, cursorOffset) {
        var curval    = this.pbTextfieldValue();
        var caret     = this.pbTextfieldCursorPosition();
        var lineStart = curval.lastIndexOf("\n", caret-1)+1;
        var lineEnd   = curval.indexOf("\n", caret);
        if (lineEnd < 0) {
            lineEnd =  curval.length;
        }

        if (typeof  val === 'undefined') {
            var line = curval.substring(lineStart, caret)+'___'+curval.substring(caret,lineEnd);
            return line;
        }
        else {
            var newval = curval.substr(0,lineStart)+val+curval.substr(lineEnd);
            this.pbTextfieldValue(newval);
            if (typeof cursorOffset !== 'undefined') {
                this.pbTextfieldCursorPosition(lineStart + cursorOffset);
            }
        }
    };

    function getValue() {
        var $element = this;

        switch($element[0].nodeName) {
            case 'INPUT':
            case 'TEXTAREA':
                return $element.val();
            default:
                // contenteditable
                // http://jsfiddle.net/nick_craver/UjZEN/2/
                var ce = $("<pre />").html($element.html());
                if ($.browser.webkit) {
                    ce.find("div").replaceWith(function() { return "\n" + this.innerHTML; });
                }
                else if ($.browser.msie) {
                    ce.find("p").replaceWith(function() { return this.innerHTML + "<br>"; });
                }
                else if ($.browser.mozilla || $.browser.opera || $.browser.msie) {
                    ce.find("br").replaceWith("\n");
                }

                return ce.text();
        }
    }

    function setValue(val) {
        var $element = this;

        switch($element[0].nodeName) {
            case 'INPUT':
            case 'TEXTAREA':
                $element.val(val);
                break;
            default:
                // contenteditable
                $element.text(val);
        }
    }

    function getCursorPosition() {
        var $element = this;
        var element = $element[0];

        switch(element.nodeName) {
            case 'INPUT':
            case 'TEXTAREA':
                var iCaretPos = 0;

                // IE Support
                if (document.selection) {
                    element.focus();
                    range = document.selection.createRange();

                    if (range && range.parentElement() == element) {
                        len = element.value.length;
                        normalizedValue = element.value.replace(/\r\n/g, "\n");

                        // Create a working TextRange that lives only in the input
                        textInputRange = element.createTextRange();
                        textInputRange.moveToBookmark(range.getBookmark());

                        // Check if the start and end of the selection are at the very end
                        // of the input, since moveStart/moveEnd doesn't return what we want
                        // in those cases
                        endRange = element.createTextRange();
                        endRange.collapse(false);

                        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                            start = len;
                        } else {
                            start = -textInputRange.moveStart("character", -len);
                            //start += normalizedValue.slice(0, start).split("\n").length - 1;
                        }

                        iCaretPos = start;
                    }
                }

                // Firefox support
                else if (element.selectionStart || element.selectionStart == '0') {
                    iCaretPos = element.selectionStart;
                }

                // Return results
                return iCaretPos;
            default:
                // http://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container/4812022#4812022
                var caretOffset = 0;
                var doc = element.ownerDocument || element.document;
                var win = doc.defaultView || doc.parentWindow;
                var sel;
                if (typeof win.getSelection != "undefined") {
                    var range = win.getSelection().getRangeAt(0);
                    var preCaretRange = range.cloneRange();
                    preCaretRange.selectNodeContents(element);
                    preCaretRange.setEnd(range.endContainer, range.endOffset);
                    caretOffset = preCaretRange.toString().length;
                } else if ( (sel = doc.selection) && sel.type != "Control") {
                    var textRange = sel.createRange();
                    var preCaretTextRange = doc.body.createTextRange();
                    preCaretTextRange.moveToElementText(element);
                    preCaretTextRange.setEndPoint("EndToEnd", textRange);
                    caretOffset = preCaretTextRange.text.length;
                }
                return caretOffset;
        }
    }

    function setCursorPosition(val) {
        var $element = this;
        var element = $element[0];

        switch(element.nodeName) {
            case 'INPUT':
            case 'TEXTAREA':
                element.selectionStart = element.selectionEnd = val;
                break;
            default:
                // contenteditable
                console.log('cannot set cursor position of contenteditable yet');
                break;
        }
    }
})();
