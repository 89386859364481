(function(undefined) {

    var index = 0;
    var cssRules = [];
    var rulesSheet;

    $.fn.pbSetArrow = function(offset, rightSide) {
        this.each(function() {
            $el = $(this);

            var uniqueClass;
            if (!$el.data('pb-arrow-unique-class')) {
                uniqueClass = 'pb-arrow-'+index;
                index++;
                $el.addClass(uniqueClass);
                $el.data('pb-arrow-unique-class', uniqueClass);
            }
            else {
                uniqueClass= $el.data('pb-arrow-unique-class');
            }

            if (offset === undefined) {
                removeRulesForSelector('.'+uniqueClass+':before');
                removeRulesForSelector('.'+uniqueClass+':after');
            }
            else {
                var rule = {};
                rule[rightSide ? 'right' : 'left'] = Math.ceil(offset) + 'px';
                rule[rightSide ? 'left' : 'right'] = 'auto';

                var beforeruleid = addRule('.'+uniqueClass+':before', rule);
                var afterruleid = addRule('.'+uniqueClass+':after', rule);
            }
        });
    };

    function addRule(selector, css) {
        if (rulesSheet === undefined) {
            var style = document.createElement('style');
            rulesSheet = document.head.appendChild(style).sheet;
        }

        propText = '';
        for (var p in css) {
            propText += p+':'+css[p]+';';
        }
        rulesSheet.insertRule(selector + "{" + propText + "}", rulesSheet.cssRules.length);
        cssRules.push(selector);
    }

    function removeRulesForSelector(selector) {
        if (rulesSheet === undefined) {
            // no sheet, so nothing to do
            return;
        }

        // remove rule
        for (var i = 0; i < cssRules.length; i++) {
            if (cssRules[i] == selector) {
                rulesSheet.deleteRule(i);
                cssRules.splice(i,1);
                i--;
            }
        }
    }

})();
