(function() {
    var BLUR_TIME = 200;
    var ACTIVE_CLASS = 'active';
    var POPUP_OPTIONS = { closeOnMouseLeave: false };

    AUTOCOMPLETE_DEFAULTS = {
        matchGroup:         0,
        beforeGroup:        -1,
        afterGroup:         -1,
        rms:                null,
        regex:              null,
        action:             null,
        usePopup:           null,
        suggestionSelector: '[data-autocomplete-value]',
    };

    $.fn.pbAutocompleteEnable = function(inputs, options) {
        var $suggestions = this;

        options = processOptions($suggestions, options);

        if (!options) {
            return;
        }

        findInputs(inputs, options, function() {
            enableForInput($(this), options);
        });

        options.$suggestions.on('click', options.suggestionSelector, suggestionClickListener);
        options.$suggestions.on('mouseenter', options.suggestionSelector, suggestionMouseEnterListener);
    };

    $.pbOnNewContent(function($container) {
        $container.find('.pb-autocomplete-scope[pb-auto-autocomplete] .pb-autocomplete-suggestions').each(function() {
            $(this).pbAutocompleteEnable();
        });
    });

    function findInputs(inputs, options, eachFn) {
        var $inputs;
        if (inputs) {
            $inputs = $(inputs);
        }
        else {
            $inputs = options.$suggestions.parents('.pb-autocomplete-scope').find('input[type=text],textarea,[contenteditable]');
        }

        if (eachFn) {
            $inputs.each(eachFn);
        }

        return $inputs;
    }

    function enableForInput($input, options) {
        $input.on('focus', function() {
            clearTimeout($input.data('blurtimeout'));
            $(this).on('input', inputlistener);
        });
        $input.on('blur', function() {
            var $input = $(this);
            $input.off('input', inputlistener);

            $input.data('blurtimeout', setTimeout(function() {
                closeMatcher($input);
            }, BLUR_TIME));
        });

        if ($input.is(':focus')) {
            $input.on('input', inputlistener);
        }

        var matchers = $input.data('autocomplete-matchers');
        if (!matchers) {
            matchers = [];
        }

        matchers.push(options);
        $input.data('autocomplete-matchers', matchers);
    }

    function inputlistener() {
        var $input = $(this);
        var line = $input.pbTextfieldCurrentLine();
        matchLine(line, $input);
    }

    function matchLine(line, $input) {
        var matchers = $input.data('autocomplete-matchers');
        for (var i = 0; i < matchers.length; i++) {
            var match = line.match(matchers[i].regex);

            if (match) {
                var text = match[matchers[i].matchGroup];
                text = text.replace('___','');

                haveMatch($input, text, matchers[i]);

                // return as we don't need to look for another match, we found
                // one.  We only match one matcher at a time.
                return;
            }
        }

        closeMatcher($input);
    }

    function haveMatch($input, text, matcher) {
        matcher.action(text, matcher, haveSuggestionsForMatcher);

        function haveSuggestionsForMatcher(suggestions) {
            if (suggestions) {
                matcher.$suggestions.html(suggestions);

                openMatcher($input, matcher);
                ensureHaveSelection(matcher);
            }
            else {
                closeMatcher($input);
            }
        }
    }

    function suggestionMouseEnterListener() {
        var $suggestion = $(this);
        var $suggestions = $suggestion.parents('.pb-autocomplete-suggestions');
        var matcher = $suggestions.data('matcher');
        selectSuggestion(matcher, $suggestion);
    }

    function suggestionClickListener() {
        var $suggestion = $(this);

        var value = $suggestion.data('autocompleteValue');
        var $suggestions = $suggestion.parents('.pb-autocomplete-suggestions');
        var matcher = $suggestions.data('matcher');

        var $input = matcher.$input;

        var line        = $input.pbTextfieldCurrentLine();

        var matchStart  = line.search(matcher.regex);
        var match       = line.match(matcher.regex);
        var before      = matcher.beforeGroup > -1 ? match[matcher.beforeGroup] : '';
        var after       = matcher.afterGroup > -1 ? match[matcher.afterGroup] : '';
        var replacement = before + value + after;
        var newLine     = line.replace(matcher.regex, replacement);

        $input.pbTextfieldCurrentLine(newLine, matchStart + replacement.length);
        closeMatcher($input);
    }

    function ensureHaveSelection(matcher) {
        var $selectedSuggestion = matcher.$suggestions.find('.'+ACTIVE_CLASS);
        if (!$selectedSuggestion.length) {
            // no selection, so select first one
            var $firstSuggestion = matcher.$suggestions.find(matcher.suggestionSelector).first();
            selectSuggestion(matcher, $firstSuggestion);
        }
    }

    function selectSuggestion(matcher, $suggestion) {
        matcher.$suggestions.find('.'+ACTIVE_CLASS).removeClass(ACTIVE_CLASS);
        $suggestion.addClass(ACTIVE_CLASS);
    }

    function rmsSearchAction(text, matcher, cb) {
        var data = jQuery.extend({}, matcher.rms);
        data.search = text;

        $.rmsSend(data, function(response) {
            cb(response.rmsD.list);
        });
    }

    function suggestionsKeydownListener(e) {
        var $input = $(this);
        var matcher = $input.data('open-matcher');

        switch(e.keyCode) {
            case 13: // enter
                matcher.$suggestions.find('.'+ACTIVE_CLASS).click();
                e.preventDefault();
                break;
            case 27: // escape
                closeMatcher($input);
                break;
            case 38: // up
                var $prev = matcher.$suggestions.find('.'+ACTIVE_CLASS).prevAll(matcher.suggestionSelector).first();
                if ($prev.length < 1) {
                    $prev = matcher.$suggestions.find(matcher.suggestionSelector).last();
                }
                selectSuggestion(matcher, $prev);

                e.preventDefault();
                break;
            case 40: // down
                var $next = matcher.$suggestions.find('.'+ACTIVE_CLASS).nextAll(matcher.suggestionSelector).first();
                if ($next.length < 1) {
                    $next = matcher.$suggestions.find(matcher.suggestionSelector).first();
                }
                selectSuggestion(matcher, $next);

                e.preventDefault();
                break;
        }
    }

    function processOptions($suggestions, options) {
        options = $suggestions.pbGetOptions(options, 'autocomplete', AUTOCOMPLETE_DEFAULTS);

        options.$suggestions = $suggestions;
        options.$suggestions.data('matcher', options);

        if (options.regex) {
            if (options.regex instanceof RegExp) {
                // already good
            }
            else {
                options.regex = new RegExp(options.regex.substr(1,options.regex.length-2));
            }
        }
        else {
            // need a regular expression, so if one wasn't specified, then just
            // match all text
            options.regex = /^.*$/;
            options.matchGroup = 0;
            options.beforeGroup = -1;
            options.afterGroup = -1;
        }

        if (options.usePopup === null) {
            options.usePopup = options.$suggestions.hasClass('pb-popup');
        }

        if (options.action === null) {
            if (options.rms) {
                if (!$.isPlainObject(options.rms)) {
                    console.error('could not parse rms call for autocomplete');
                    return;
                }
                else {
                    options.action = rmsSearchAction;
                }
            }
            else {
                console.error('could not find action for this autocomplete');
                return;
            }
        }

        return options;
    }

    function openMatcher($input, matcher) {
        var prevmatcher = $input.data('open-matcher');
        if (prevmatcher) {
            if (prevmatcher !== matcher) {
                // another matcher is open!  close it
                console.error('another matcher is open! this should not happen!')
                closeMatcher($input);
            }
            else {
                // already open, don't do anything
                return;
            }
        }
        matcher.$input = $input;
        $input.on('keydown', suggestionsKeydownListener);

        $input.data('open-matcher', matcher);

        if (matcher.usePopup) {
            matcher.$suggestions.pbOpenPopup($input, POPUP_OPTIONS);
        }
        else {
            //
        }
    }

    function closeMatcher($input) {
        var matcher = $input.data('open-matcher');
        if (matcher) {
            $input.off('keydown', suggestionsKeydownListener);

            $input.removeData('open-matcher');
            matcher.$input = null;

            if (matcher.usePopup) {
                matcher.$suggestions.pbClosePopup(POPUP_OPTIONS);
            }
            else {
                matcher.$suggestions.html('');
            }
        }
    }

})();
