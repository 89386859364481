(function() {
    var undefined;

    function indexOf(arr, val) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                return i;
            }
        }

        return -1;
    }

    $.fn.pbValidation_watchField = function() {
        if(this.length !== 1) {
            this.each(function() {
                $(this).pbValidation_watchField();
            });
            return;
        }

        var $container = this;
        $container.find('textarea,input,select').on('input change', function() {
            var $field = $(this);
            var $parent = $field.parents('.pb-validation-container');

            if ($parent.hasClass('invalid')) {
                $(this).pbValidation_validateField(true);
            }
        });
        $container.find('textarea,input,select').on('blur', function() {
            var $field = $(this);
            $field.pbHideTooltip();
        });
    };

    $.fn.pbValidation_validateForm = function(e) {
        if(this.length !== 1) {
            this.each(function() {
                $(this).pbValidation_validateForm(e);
            });
            return;
        }

        var $form = this;
        var fieldstack = [];
        var fields = {};

        var serialized = $form.serializeArray();
        for (var i = 0; i < serialized.length; i++) {
            var name = serialized[i].name;
            var value = serialized[i].value;

            var match = name.match(/^fieldstack\[([0-9]+)\]$/);
            if (match) {
                fieldstack[match[1]] = value;
            }
            else {
                var nameParts = name.split('-');

                if (indexOf(fieldstack, nameParts[0]) > -1) {
                    fields[nameParts[0]] = {
                        'validators': nameParts.slice(1),
                        'value': value,
                        'inputName': name,
                    };
                }
            }
        }
        for (i = 0; i < fieldstack.length; i++) {
            var fieldName = fieldstack[i];
            var field = fields[fieldName];

            if (field) {
                var $field = $form.find('[name="'+field.inputName+'"]');
                var valid = $field.pbValidation_validateField(true);

                if (!valid) {
                    // TODO check if the element is visible first
                    $('html, body').animate({scrollTop: Math.max(0,$field.offset().top-50)}, 200);

                    if (e) {
                        wO = undefined;
                        e.preventDefault();
                    }
                    return false;
                }
            }
        }

        return true;
    };

    $.fn.pbValidation_validateField = function(markinvalid) {
        var $field = this;

        var value = $field.val();

        var name = $field.attr('name');
        var nameParts = name.split('-');
        var validators = nameParts.slice(1);

        var err = checkTypes(value, validators);
        if (err) {
            if (markinvalid) {
                markFieldAsInvalid($field, err);
            }
            return false;
        }
        else {
            removeInvalidFieldMark($field);
            //markFieldAsValid($field);
            return true;
        }
    };

    function removeInvalidFieldMark(field) {
        var $field = $(field);
        var $parent = $field.parents('.pb-validation-container');

        $field.pbHideTooltip();

        if ($parent.length > 0) {
            if ($parent.attr('data-has-form-validation-class')) {
                $parent.attr('data-has-form-validation-class',0);
            }
            $parent.removeClass('invalid');
        }
    }

    function markFieldAsInvalid(field, err) {
        var $field = $(field);

        var $tooltipAnchor = $field.attr('type') === 'hidden' ? $field.parent() : $field;
        $tooltipAnchor.pbShowTooltip(err, {classNames: 'error', closeOnMouseLeave: false});

        var $parent = $field.parents('.pb-validation-container');

        if ($parent.length > 0) {
            if (!$parent.hasClass('invalid') && !$parent.hasClass('valid') && !$parent.attr('data-has-form-validation-class')) {
                $parent.attr('data-has-form-validation-class',1);
            }
            $parent.addClass('invalid');
        }
    }

    function markFieldAsValid(field) {
        var $field = $(field);

        var $parent = $field.parents('.pb-validation-container');

        if ($parent.length > 0) {
            $parent.addClass('valid');
        }
    }

    function checkTypes(value, validators) {
        // special case the 'emptyok' validation
        if (validators[0] === 'emptyok' && value === '') {
            return;
        }

        for (var i = 0; i < validators.length; i++) {
            var err = checkType(value, validators[i]);
            if (err) {
                return err;
            }
        }
    }

    function checkType(value, validator) {
        for (var i = 0; i < validatorFns.length; i++) {
            var checkFn = validatorFns[i][1];
            var checked = checkFn(validator);
            if (checked) {
                var validateFn = validatorFns[i][2];
                var err = validateFn(value, validator);

                if (err) {
                    return err;
                }

                break;
            }
        }
    }

    var validatorFns = [
        ['>', check_gt, validate_gt],
        ['<', check_lt, validate_lt],
        ['locationselect', check_locationselect, validate_locationselect],
        ['float=<', check_floatlte, validate_floatlte],
        ['float<', check_floatlt, validate_floatlt],
        ['float=>', check_floatgte, validate_floatgte],
        ['float>', check_floatgt, validate_floatgt],
        ['email', check_email, validate_email],
        ['alpha', check_alpha, validate_alpha],
        ['alphanum', check_alphanum, validate_alphanum],
        ['float', check_float, validate_float],
        ['int', check_int, validate_int],
        ['intplus', check_intplus, validate_intplus],
        ['username', check_username, validate_username],
        ['text', check_text, validate_text],
        ['productname', check_productname, validate_productname],
    ];

    function check_gt(validator) {
        return validator.substr(0,2) === 'gt' || validator.substr(0,1) === '>';
    }
    function validate_gt(value, validator) {
        var len = Number(validator.substr(2));

        if (value.length <= len) {
            var limit = len + 1;
            var msg = 'This field must have at least '+limit+' character'+(limit == 1 ? '' : 's');
            return msg;
        }
    }


    function check_lt(validator) {
        return validator.substr(0,2) === 'lt' || validator.substr(0,1) === '<';
    }
    function validate_lt(value, validator) {
        var len = Number(validator.substr(2));

        if (value.length < len) {
            return false;
        }
        else {
            var limit = len + 1;
            var msg = 'This field must have fewer than '+limit+' character'+(limit == 1 ? '' : 's');
            return msg;
        }
    }

    function check_locationselect(validator) {
        return validator === 'locationselect';
    }
    function validate_locationselect(value, validator) {
        if (value == -1 || value == -99) {
            var msg = 'Must select';
            return msg;
        }
        else {
            return false;
        }
    }

    function check_floatlt(validator) {
        return validator.substr(0,6) === 'float<' || validator.substr(0,7) === 'floatlt';
    }
    function validate_floatlt(value, validator) {
        if (!$.isNumeric(value)) {
            return 'This field must be a number';
        }

        var f;
        var numValue = Number(value);
        if (validator.substr(0,6) === 'float<') {
            f = Number(validator.substr(6));
        }
        else {
            f = Number(validator.substr(7));
        }
        console.log('hi', f, numValue);

        if (numValue >= f) {
            return 'This field must be less than '+f;
        }
    }

    function check_floatlte(validator) {
        return validator.substr(0,7) === 'float=<' || validator.substr(0,8) === 'floatlte' || validator.substr(0,8) === 'float=lt';
    }
    function validate_floatlte(value, validator) {
        if (!$.isNumeric(value)) {
            return 'This field must be a number';
        }

        var f;
        var numValue = Number(value);
        if (validator.substr(0,7) === 'float=<') {
            f = Number(validator.substr(7));
        }
        else {
            f = Number(validator.substr(8));
        }

        if (numValue > f) {
            return 'This field must be less than or equal to '+f;
        }
    }

    function check_floatgt(validator) {
        return validator.substr(0,6) === 'float>' || validator.substr(0,7) === 'floatgt';
    }
    function validate_floatgt(value, validator) {
        if (!$.isNumeric(value)) {
            return 'This field must be a number';
        }

        var f;
        var numValue = Number(value);
        if (validator.substr(0,6) === 'float>') {
            f = Number(validator.substr(6));
        }
        else {
            f = Number(validator.substr(7));
        }

        if (numValue <= f) {
            return 'This field must be greater than '+f;
        }
    }

    function check_floatgte(validator) {
        return validator.substr(0,7) === 'float>=' || validator.substr(0,8) === 'floatgte' || validator.substr(0,8) === 'float=gt';
    }
    function validate_floatgte(value, validator) {
        if (!$.isNumeric(value)) {
            return 'This field must be a number';
        }

        var f;
        var numValue = Number(value);
        if (validator.substr(0,7) === 'float>=') {
            f = Number(validator.substr(7));
        }
        else {
            f = Number(validator.substr(8));
        }

        if (numValue < f) {
            return 'This field must be greater than or equal to '+f;
        }
    }

    function check_email(validator) {
        return validator.substr(0,6) === 'email';
    }
    function validate_email(value, validator) {
        // http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(value)) {
            return 'Invalid email address';
        }
    }

    function check_alpha(validator) {
        return validator === 'alpha';
    }
    function validate_alpha(value, validator) {
        var re = /^[A-Za-z]*$/;
        if (!re.test(value)) {
            return 'Only letter characters allowed. No white space or numbers.';
        }
    }

    function check_alphanum(validator) {
        return validator === 'alpha';
    }
    function validate_alphanum(value, validator) {
        var re = /^[A-Za-z0-9]+$/;
        if (!re.test(value)) {
            return 'Only letter and number characters allowed. No white space.';
        }
    }

    function check_float(validator) {
        return validator === 'float';
    }
    function validate_float(value, validator) {
        if (!$.isNumeric(value)) {
            return 'This field must be a number';
        }
    }

    function check_int(validator) {
        return validator === 'int';
    }
    function validate_int(value, validator) {
        if (value != parseInt(value)) {
            return 'Only integers';
        }
    }

    function check_intplus(validator) {
        return validator === 'int+';
    }
    function validate_intplus(value, validator) {
        if (value != parseInt(value)) {
            return 'Only integers';
        }
        if (value < 0) {
            return 'Must be greater than or equal to zero';
        }
    }

    function check_username(validator) {
        return validator === 'username';
    }
    function validate_username(value, validator) {
        var re = /^[A-Za-z0-9\-]+$/;
        if (!re.test(value)) {
            return 'Only letters, numbers and dashes allowed. No white space.';
        }
    }

    function check_text(validator) {
        return validator === 'text' || validator === 'textplain';
    }
    function validate_text(value, validator) {
        var re = /^[A-Za-z0-9\-]+$/;
        if (!re.test(value)) {
            return 'Only letters, numbers, dashes and spaces allowed';
        }
    }

    function check_productname(validator) {
        return validator === 'productname';
    }
    function validate_productname(value, validator) {
        var re = /^[A-Za-z0-9\-_.\,\(\)'\"\+ ]+$/;
        if (!re.test(value)) {
            return 'Only letters, numbers dashes, underlines, periods, commas, (, ), \', ", +, and spaces allowed';
        }
    }
})();
