(function() {
    var ready = false;
    var listeners = [];
    var numListeners = 0;

    $.pbOnNewContent = function(fn) {
        listeners.push(fn);
        numListeners++;
        if (ready) {
            fn($(document.body));
        }
    };

    $.pbNewContent = function(container) {
        var $container = $(container);
        for (var i = 0; i < numListeners; i++) {
            listeners[i]($container);
        }
    };

    $(function() {
        ready = true;
        $.pbNewContent(document.body);
    });
})();
