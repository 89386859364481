(function() {
    var tooltipTimeout;

    var DEFAULT_OPTIONS = {
        classNames:        '',
        fadeInTime:        200,
        fadeOutTime:       200,
        closeOnMouseLeave: 200,
    };

    $.fn.pbShowTooltip = function(msg, options) {
        if (msg !== "" && msg !== undefined) {
            $('.pb-tooltip').detach();

            var $el = $(this);
            options = $el.pbGetOptions(options, 'tooltip', DEFAULT_OPTIONS);

            var $tooltip = $el.data('$tooltip');

            if (!$tooltip) {
                // if they set the title element, remove it, so they don't get
                // double titles from built-in browser tooltips
                if ($el.attr('title')) {
                    $el.data('pb-tooltip-title', $el.attr('title'));
                    $el.removeAttr('title');
                }

                $tooltip = $('<div class="pb-tooltip"></div>');

                if (options.classNames) {
                    $tooltip.addClass(options.classNames);
                }

                $tooltip.hide();

                $tooltip.on('pbPopupClosed', function() {
                    $tooltip.detach();
                });

                $el.data('$tooltip', $tooltip);
            }

            $tooltip.html(msg);
            $(document.body).append($tooltip);
            $tooltip.pbOpenPopup($el, options);
        }
    };


    $.fn.pbHideTooltip = function(msg, options) {
        $el = $(this);
        options = $el.pbGetOptions(options, 'tooltip', DEFAULT_OPTIONS);

        var $tooltip = $('.pb-tooltip');
        $tooltip.pbClosePopup(options);
    };

    $.pbOnNewContent(function($container) {
        $container.find('[pb-auto-tooltip]').on('mouseenter', tooltip_mouseenter_listener);
    });

    function tooltip_mouseenter_listener() {
        $el = $(this);
        var msg = $el.attr('title') ||$el.data('pb-tooltip-title');
        $el.pbShowTooltip(msg);
    }

})();
