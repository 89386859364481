(function() {
    var BUTTON_ACTIVE_CLASS = 'target-visible';

    var DEFAULT_OPTIONS = {
        toggleButtonActive: true,
        closeOnBodyClick:   true,
        closeOnMouseLeave:  1500, // if false then it won't close on mouseleave
        fadeInTime:         75,
        fadeOutTime:        75,
        alignment:          '',
        windowEdgeBuffer:   25,
    };

    $.fn.pbTogglePopup = function(anchor, options) {
        this.each(function() {
            toggle($(this), anchor, options);
        });
        return this;
    };

    $.fn.pbOpenPopup = function(anchor, options) {
        this.each(function() {
            open($(this), anchor, options);
        });
        return this;
    };

    $.fn.pbClosePopup = function(options) {
        this.each(function() {
            close($(this), options);
        });
        return this;
    };

    $.pbOnNewContent(function($container) {
        $container.find('.pb-popup-anchor[pb-auto-popup]').on('click', popup_click_listener);
    });

    function popup_click_listener(e) {
        var $popup = $(this).find('.pb-popup');

        if ($popup[0] !== e.target && !$popup.has(e.target).length) {
            $popup.pbTogglePopup();
        }
    }

    function toggle($el, anchor, options) {
        var opening = $el.is(':hidden');
        if (opening) {
            open($el, anchor, options);
        }
        else {
            close($el, options);
        }
    }

    function findAnchor($el, anchor, options) {
        if (anchor) {
            return $(anchor);
        }
        else {
            return $el.parents('.pb-popup-anchor');
        }
    }

    function toggleButtonActive($anchor, options) {
        if (options.toggleButtonActive) {
            var $btn;
            if ($anchor.is('.pb-button')) {
                $btn = $anchor;
            }
            else if ($anchor.find('> .pb-button').length) {
                $btn = $anchor.find('> .pb-button');
            }

            if ($btn) {
                $btn.toggleClass(BUTTON_ACTIVE_CLASS);

                $btn[0].blur();
            }
        }
    }

    function position($el, $anchor, options) {
        var visible = $el.is(':visible');
        if (!visible) {
            $el.show();
        }

        var anchorOffset = $anchor.offset();
        anchorOffset.height = $anchor.outerHeight();
        anchorOffset.width = $anchor.outerWidth();
        anchorOffset.bottom = anchorOffset.top+anchorOffset.height;
        anchorOffset.right = anchorOffset.left+anchorOffset.width;
        anchorOffset.center = (anchorOffset.left+anchorOffset.right)/2;

        var elOffsetParent = $el.offsetParent().offset();
        var elWidth = $el.outerWidth();
        var windowWidth = $(window).width();

        if (options.alignment === 'right' || (!options.alignment && anchorOffset.center+elWidth/2 > anchorOffset.right && (anchorOffset.center+elWidth/2+options.windowEdgeBuffer) > windowWidth)) {
            // right align
            $el.css({
                top: anchorOffset.bottom - elOffsetParent.top,
                left: anchorOffset.right - elWidth - elOffsetParent.left,
            });

            $el.pbSetArrow(anchorOffset.width/2, true);
        }
        else if (options.alignment === 'left' || (!options.alignment && anchorOffset.center-elWidth/2 < anchorOffset.left && (anchorOffset.center-elWidth/2-options.windowEdgeBuffer) < 0)) {
            // left align
            $el.css({
                top: anchorOffset.bottom - elOffsetParent.top,
                left: anchorOffset.left -  elOffsetParent.left,
            });

            $el.pbSetArrow(anchorOffset.width/2);
        }
        else {
            // center
            $el.css({
                top: anchorOffset.bottom - elOffsetParent.top,
                left: anchorOffset.center - elWidth/2 - elOffsetParent.left
            });

            $el.pbSetArrow();
        }

        if (!visible) {
            $el.hide();
        }
    }

    function addListeners($el, $anchor, options) {
        if (!$el.data('pb-popup-resizelistener')) {
            $el.data('pb-popup-resizelistener', function() {
                position($el, $anchor, options);
            });
        }
        $(window).on('resize', $el.data('pb-popup-resizelistener'));

        if (options.closeOnBodyClick) {
            if (!$el.data('pb-popup-bodyclicklistener')) {
                $el.data('pb-popup-bodyclicklistener', function (e) {
                    if ($anchor[0] !== e.target && !$anchor.has(e.target).length && $el[0] !== e.target && !$el.has(e.target).length) {
                        close($el, options);
                    }
                });
            }
            // run this in a timeout to avoid clicks from other buttons triggering
            // this immediately as the event bubbles up the DOM
            setTimeout(function() {
                $(document.body).on('click', $el.data('pb-popup-bodyclicklistener'));
            },13);
        }

        if (options.closeOnMouseLeave !== false) {
            if (!$el.data('pb-popup-mouseenter')) {
                $el.data('pb-popup-mouseenter', function(e) {
                    clearTimeout($el.data('pb-popup-hide_timeout'));
                });
            }
            $anchor.on('mouseenter', $el.data('pb-popup-mouseenter'));
            $el.on('mouseenter', $el.data('pb-popup-mouseenter'));

            if (!$el.data('pb-popup-mouseleave')) {
                $el.data('pb-popup-mouseleave', function(e) {
                    clearTimeout($el.data('pb-popup-hide_timeout'));

                    var t = setTimeout(function() {
                        close($el, options);
                    }, options.closeOnMouseLeave);
                    $el.data('pb-popup-hide_timeout', t);
                });
            }
            $anchor.on('mouseleave', $el.data('pb-popup-mouseleave'));
            $el.on('mouseleave', $el.data('pb-popup-mouseleave'));
        }
    }

    function removeListeners($el, $anchor) {
        if ($el.data('pb-popup-resizelistener')) {
            $(window).off('resize', $el.data('pb-popup-resizelistener'));
        }

        if ($el.data('pb-popup-bodyclicklistener')) {
            $(document.body).off('click', $el.data('pb-popup-bodyclicklistener'));
        }

        if ($el.data('pb-popup-mouseenter')) {
            $el.off('mouseenter', $el.data('pb-popup-mouseenter'));
            $anchor.off('mouseenter', $el.data('pb-popup-mouseenter'));
        }

        if ($el.data('pb-popup-mouseleave')) {
            $el.off('mouseleave', $el.data('pb-popup-mouseleave'));
            $anchor.off('mouseleave', $el.data('pb-popup-mouseleave'));
        }
    }

    function open($el, anchor, options) {
        options = $el.pbGetOptions(options, 'popup', DEFAULT_OPTIONS);

        var $anchor = findAnchor($el, anchor, options);

        var $currentanchor = $el.data('pb-popup-currentanchor');
        if ($currentanchor && $currentanchor[0] === $anchor[0]) {
            // already open and at this anchor

            // we toggle the listeners on and off to ensure that the body
            // click listener doesn't close this popup right away
            removeListeners($el, $anchor);
            addListeners($el, $anchor, options);

            // reposition in case that's what's needed
            position($el, $anchor, options);
        }
        else {
            // not open, need to open it
            if ($currentanchor) {
                // the popup is already open some where on the page, so close it
                // first...
                close($el, options);
                $el.hide();
            }

            position($el, $anchor, options);
            toggleButtonActive($anchor, options);
            addListeners($el, $anchor, options);

            if (options.fadeInTime > 0) {
                $el.stop(true);

                // if not fully visible, then animate from there, otherwise, set
                // to hidden and then fade in
                var o = $el.css('opacity');
                if (o == 1) { $el.css('opacity', 0); }

                $el.show();
                $el.animate({opacity: 1}, options.fadeInTime);
            }
            else {
                $el.show();
            }

            $el.data('pb-popup-currentanchor', $anchor);
            $el.trigger('pbPopupOpened');
        }
    }

    function close($el, options) {
        options = $el.pbGetOptions(options, 'popup', DEFAULT_OPTIONS);

        var $anchor = $el.data('pb-popup-currentanchor');
        if ($anchor) {
            toggleButtonActive($anchor, options);
            removeListeners($el, $anchor);

            if (options.fadeOutTime > 0) {
                $el.stop(true);
                $el.animate({opacity: 0}, options.fadeOutTime, done);
            }
            else {
                done();
            }

            $el.removeData('pb-popup-currentanchor');
        }

        function done() {
            // always leave it fully visible, but hidden (so a $.fn.show() call
            // will show it)
            $el.css('opacity', 1);
            $el.hide();

            $el.trigger('pbPopupClosed');
        }
    }
})();
